import asls from "./asls.minilanding"
import roomz from "./roomz.minilanding"
import lazy from "./lazy.minilanding"
import ml from "./ml.minilanding"

export default {
  asls,
  roomz,
  lazy,
  ml
}
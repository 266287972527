<template>
  <div
    @click="$emit('pressed')"
    :class="{ light, filled }"
    class="btn font-medium cursor-pointer uppercase flex items-center justify-center bottom-5 right-5 rounded-full h-12 md:pl-8 md:pr-8 flex-1 lg:flex-none"
  >
    <span class="text">{{ title }}</span>
    <eva-icon v-if="filled" class="icon" name="arrow-right" :fill="light ? '#e7e7e7':'#222020'"></eva-icon>
    <eva-icon v-else class="icon" name="arrow-down" :fill="light ? '#222020':'#e7e7e7'"></eva-icon>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    filled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  name: "CTAComponent",
};
</script>

<style scoped>
.btn {
  border: 2px solid #e7e7e7;
  color: #e7e7e7;
  border-radius: 50px;
}
.btn.light {
  border-color: #222020;
  color: #222020;
}
.btn.light.filled {
  background: #222020;
  color: #e7e7e7;
}
.btn.filled {
  background: #e7e7e7;
  color: #222020;
  border-color: transparent;
}
.btn:hover {
  opacity: 1!important;
}
.btn:hover > .text{
  transform: translateX(-8px);
}

.text{
  transform: translateX(0px);
  height: 100%;
  display: flex;
  align-items: center;
  transition: transform .1s;
  font-weight: 600;
}
.icon{
  width: 0px;
  opacity: 0;
  transition: transform .1s, opacity .1s;
}
.btn:hover > .icon{
  opacity: 1;
  transform: translateX(-8px);
}
</style>
